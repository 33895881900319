<template>
  <div class="sort-select">
    <v-menu v-model="show" right offset-y nudge-bottom="10px">
      <template v-slot:activator="{ on, attrs, value: v }">
        <Chip v-bind="attrs" v-on="on">
          <span>{{ name }}</span>
          <v-icon class="ml-2" color="primary" v-if="v">mdi-menu-up</v-icon>
          <v-icon class="ml-2" color="primary" v-else>mdi-menu-down</v-icon>
        </Chip>
      </template>
      <List :list="list" :value="value" @input="input"> </List>
    </v-menu>
  </div>
</template>

<script lang="js">
import Chip from '@/components/Chip.vue';
import List from '@/components/List.vue';

export default {
  name: 'SortSelect',
  components: { Chip, List },
  props: {
      value: [String, Number],
      list: {
        type: Array,
        default: () => []
      }
  },
  data: function() {
    return {
        show: false,
        // nameValue:''
    };
  },
  computed: {
    selected: function() {
      return this.list.find(v =>
	   v.value === this.value);
    },
    name: function() {
      console.log(this.selected,this.list)
      return this.selected ? this.selected.label :  '';
    }
  },
//   watch:{
// name(newvalue){
//   this.nameValue = newvalue
// }
//   },
  // mounted(){
  //   console.log("wxl -- 111",this.selected.label)
  //   if (this.selected) {
  //     if(this.selected.label == "All"){
  //       this.nameValue = "Token"
  //     }else{
  //        this.nameValue = this.selected.label
  //     }
      
  //   }

  // },
  methods: {
    input:function (e) {
      // console.log("wxl -- click",e)
      // if(e == ''){
      //   this.nameValue = "Token"
      // }
      this.$emit('input',  e)
    }
  }
};
</script>

<style lang="scss" scoped>

.sort-select {
    margin-left: auto;
    
    // margin-top: 40px;
    // margin-right: 50px;
    
  // @media (max-width: 1220px) {
  //   position: absolute;
  //   z-index: 1;
  //   bottom: 0;
  //   right: 0;
  // }
}
</style>
