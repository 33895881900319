<template>
   <div :class="{mobile: isMobile}">
      <div class="card-List" :class="{clearDisplay:owerfrom==='personalPage' || type=='collect'}">
         <div class="card-box" v-for="(item, i) in list" :key="i" @click="toPage(item)">
            <Card :isMinPrice="isMinPrice" :value="item" :type="type" :owerfrom="item.useType !==1 ? owerfrom : auction"/>
         </div>
      </div>
      <loading :isShowLoading="isShowLoading" ></loading>
   </div>
</template>

<script lang="js">
  import Card from "@/components/workCard/Card.vue";
  import Loading from "@/components/loading.vue";

  export default {
    name: "CardList",
    components: { Loading, Card },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      type: String,
      isShowLoading: {
        type: Boolean
      },
  
      owerfrom: String,
      isMinPrice:String
    },
    data: function() {
      return {
        auction: "auction"
      };
    },
    computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},
    methods: {
      toPage(item) {
      }
    }
  };
</script>

<style lang="scss" scoped>
   .card-List {
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // grid-gap: 32px;
      .card-box {
         //  min-width: 0 to fix grid && white-space
         min-width: 0;
         display: inline-block;
        
      
        margin-bottom: 24px;
        margin-right: 30px;
        // justify-content: space-between;
        &:nth-child(5n) {
          margin-right: 0;
        }

         &:hover {
            box-shadow: 0 0 2px #1d42ff;
            // padding: 0px;
            // margin: 9px;
            cursor: pointer;
         }
      }
      @media screen and (max-width: 500px ) {
         grid-template-columns: repeat(1, 1fr);
         .card-box {
            width: 46%;
            margin-right: 20px !important;
            &:nth-child(2n) {
               margin-right: 0 !important;
            }
            &:nth-child(5n) {
               // margin-right: 30px;
            }
         }
         .v-card {
            width: unset;
         }
      }

      @media screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
         grid-template-columns: repeat(2, 1fr);
         .v-card {
            // width: unset;
         }
      }
      @media screen and (min-width: 900px) {
         grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
         grid-template-columns: repeat(5, 1fr);
      }
   }

   .clearDisplay {
      display: block !important;
   }

   .mobile {
      .card-box {
         // display: unset;
      }
   }
</style>
