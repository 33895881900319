<template>
  <div class="container">
    <v-tabs
      background-color="#fff"
      color="#270645"
      height="40px"
      show-arrows
      center-active
      align-with-title
      v-model="activeTab"
    >
      <v-tab
        append
        v-for="item in tablist"
        :value="item.value"
        :key="item.value"
        >{{ item.label }}</v-tab
      >
      <SortSelect v-if="!isMobile" :list="selectList" v-model="activeSort" />
      <img
        :class="[rotate ? 'go' : '']"
        class="refresh"
        src="@/assets/icons/icon_refresh.png"
        @click="refresh"
      />
    </v-tabs>
    <v-tabs-items>
      <!-- <div class="handler">
        <input
          :placeholder="this.$t('creatorSearch')"
          class="search-inpt pa-5"
          v-model="ipt"
        />
        <v-spacer></v-spacer>
        <SortSelect v-if="!isMobile" :list="selectList" v-model="activeSort" />
      </div> -->
      <div class="cards-container">
        <!-- <Cards :cards="cards" /> -->
        <CardList
          :list="list"
          v-scroll="onScroll"
          owerfrom="auction"
          type="auction"
          :isShowLoading="isShowLoading"
        />
      </div>
    </v-tabs-items>
    <div class="right_bottom" v-if="!isMobile && showRightBottom">
      <img src="@/assets/icons/btn_play1.png" @click="openCarousel" alt="" />
    </div>
    <v-dialog v-model="opencard">
      <carousel
        class="otherpage"
        :list="list"
        v-if="opencard"
        @getOpen="OpenCard"
      ></carousel>
    </v-dialog>
    <div class="right_bottom1" v-if="!isMobile">
      <img
        v-if="!openmsg_group"
        src="@/assets/image/btn_service-message.png"
        alt=""
        @click="openServiceMessage"
      />
    </div>
    <div
      v-if="openmsg_group"
      style="position: fixed; right: 20px; bottom: 20px; z-index: 9999"
    >
      <message-service
        @getOpen="OpenMsg_group"
        v-if="openmsg_group"
        :currfriend="currfriend_group"
      ></message-service>
    </div>
  </div>
</template>

<script lang="js">
	import SortSelect from '@/components/SortSelect.vue';
	// import Cards from "@/views/home/components/Cards.vue";
	import CardList from "@/components/workCard/CardList.vue";
	import Carousel from "@/components/carousel";
	import api from "@/api";
	import store from '@/store';
	import MessageService from "@/components/popup/messageService.vue";
	import {
		mapState
	} from 'vuex'

	export default {
		name: 'auction',
		components: {
			SortSelect,
			CardList,
			Carousel,
			MessageService
		},
		props: {},
		data: function() {
			console.log("dddddd", sessionStorage.getItem('auction_activeSort') + "__" + sessionStorage.getItem(
				'auction_activeTab'))
			return {
				activeSort: sessionStorage.getItem('auction_activeSort') ? Number(sessionStorage.getItem(
					'auction_activeSort')) : 5,
				activeTab: sessionStorage.getItem('auction_activeTab') ? Number(sessionStorage.getItem(
					'auction_activeTab')) : undefined,
				tablist: [
					// {value: 0, label: 'ALL'},
					// {value: 3, label: 'Artwork'},
					// {value: 4, label: 'Collectibles'},
					// {value: 5, label: 'Photograph'},
					// {value: 7, label: 'Video'},
					{
						value: "0",
						label: this.$t('otherpageTabsPageAll')
					},
					{
						value: "3",
						label: this.$t('otherpageTabsPageArtwork')
					},
					{
						value: "4",
						label: this.$t('amCreateCollectibles')
					},
					{
						value: "5",
						label: this.$t('createCategoriesPhotography')
					},
					{
						value: "7",
						label: this.$t('amCreateVideo')
					},

				],
				selectList: [{
						value: 1,
						label: this.$t('marketType1')
					},
					{
						value: 3,
						label: this.$t('marketType3')
					},
					{
						value: 4,
						label: this.$t('marketType4')
					},
					{
						value: 8,
						label: this.$t('marketType8')
					},
					{
						value: 9,
						label: this.$t('marketType9')
					},
					{
						value: 5,
						label: this.$t('marketType5')
					},
					{
						value: 7,
						label: this.$t('marketType7')
					},

				],
				showMenu: false,
				list: [],
				pageNumber: 0,
				pageSize: 20,
				totalPage: false,
				isShowLoading: false,
				rotate: false,
				opencard: false,
				  showRightBottom: false,
				     currfriend_group:{},
    openmsg_group: false,

			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
			handler: function() {
				return {
					ipt: this.searchInput,
					activeTab: this.activeTab,
					activeSort: this.activeSort,
				}
			},
			...mapState({
				searchInput: state => state.searchInput,
			})
		},
		watch: {
			handler: {
				handler: function() {
					this.list = []
					this.pageNumber = 0;
					this.getList()
				},
				deep: true
			}
		},
		mounted() {
			window.eventBus.$on('LangChange', this.onLangChange);
			this.getList()
		},
		  beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name === 'Detail') {
          vm.list = [...store.state.NFTList];
          if (store.state.NFTListCursor) {
            vm.pageNumber = store.state.NFTListCursor.pageNumber;
            vm.pageSize = store.state.NFTListCursor.pageSize;
            vm.totalPage = store.state.NFTListCursor.totalPage;
          }
        } else {
          store.dispatch('setList')
        }
      });
    },
     beforeRouteLeave(to, from, next) {
       if (to.name === 'Detail') {
          store.dispatch('setList', {
            NFTList: this.list,
            NFTListCursor: {
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
              totalPage: this.totalPage,
            }
          })
        } else {
          store.dispatch('setList')
        }
      next();
  },
		methods: {

			refresh() {
				this.rotate = true;
				this.pageNumber = 1
				setTimeout(() => {
					this.rotate = false
				}, 2000);
				this.list = []
				this.pageNumber = 0
				this.getList();

			},
			getList() {
				sessionStorage.setItem('auction_activeTab', this.activeTab)
				sessionStorage.setItem('auction_activeSort', this.activeSort)
				let index = 0;
				if (this.activeTab == 0) {
					index = ""
				} else if (this.activeTab == 1) {
					index = 3
				} else if (this.activeTab == 2) {
					index = 4
				} else if (this.activeTab == 3) {
					index = 5
				} else if (this.activeTab == 4) {
					index = 7
				}
				this.pageNumber++;
				let params = {
					did: this.$store.state.did,
					attribute: index,
					pageSize: this.pageSize,
					pageNumber: this.pageNumber,
					searchKey: encodeURIComponent(this.searchInput),
					sortType: this.activeSort,
				};
				this.isShowLoading = true;
				api.home
					.getAuctionList({
						...params,
						pageNumber: this.pageNumber,
						pageSize: this.pageSize,
					})
					.then((response) => {
						const {
							list,
							totalPage
						} = response.data;
						this.pageNumber;
						this.totalPage = totalPage;
						if (this.pageNumber == 1) {
							this.list = list;
						} else {
							this.list = this.list.concat(list);
						}
						this.isShowLoading = false;
						if(this.list.length>0){
				this.showRightBottom =true
			}else{
				this.showRightBottom =false
      }

						console.log(list);
					});
			},
			onScroll(e) {
				const {
					scrollTop,
					clientHeight,
					scrollHeight
				} =
				e.target.scrollingElement;
				if (scrollTop + clientHeight >= scrollHeight && (this.totalPage > this.pageNumber)) {
					this.getList();
				}
			},
	 openCarousel() {
		this.opencard = true;
	  },
	  OpenCard(e) {
	    this.opencard = e;
	  },
	     openServiceMessage(){
      console.log("openServiceMessage")
        this.currfriend_group.fansAddress = "0x91eB1DE0543a4AA41ac960E67c1ECF729AAEdf06";
        this.currfriend_group.fansName = "Uptick CS"
        this.openmsg_group = true;

    },
     OpenMsg_group(e) {
        this.openmsg_group = e;
      },
			onLangChange() {
				this.tablist = [{
							value: "0",
							label: this.$t('otherpageTabsPageAll')
						},
						{
							value: "3",
							label: this.$t('otherpageTabsPageArtwork')
						},
						{
							value: "4",
							label: this.$t('amCreateCollectibles')
						},
						{
							value: "5",
							label: this.$t('createCategoriesPhotography')
						},
						{
							value: "7",
							label: this.$t('amCreateVideo')
						},
					],
					this.selectList =  [{
						value: 1,
						label: this.$t('marketType1')
					},
					{
						value: 3,
						label: this.$t('marketType3')
					},
					{
						value: 4,
						label: this.$t('marketType4')
					},
					{
						value: 8,
						label: this.$t('marketType8')
					},
					{
						value: 9,
						label: this.$t('marketType9')
					},
					{
						value: 5,
						label: this.$t('marketType5')
					},
					{
						value: 7,
						label: this.$t('marketType7')
					},

				]
			}
		},
	};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 0;
  padding-left: 0;

  > .container {
    padding-left: 0;
    padding-right: 0;
  }

  ::v-deep.sort-select .rd.v-chip {
    box-shadow: none;
    border: none;
    margin-bottom: 2px;
    font-weight: bold;
  }

  > .v-tabs {
    border-bottom: solid 1px #e3e3e3;

    .v-tab {
      padding: 0;
      margin-right: 50px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 120px;
      letter-spacing: 0px;
      color: #270645;
    }
  }

  .search-inpt {
    width: 600px;
    height: 41px;
    background-color: #f0f0f0;
    border-radius: 20px;
    outline-color: #f0f0f0;
  }

  .handler {
    margin-top: 28px;
    flex: 1;
    display: flex;
    height: 41px;
  }

  .cards-container {
    margin: 38px 0;
  }

  .refresh {
    height: 18px;
    width: 18px;
    margin-top: 12px;
    margin-left: 15px;
  }

  // .aa{
  //       transition: all 2s;
  //   }

  .go {
    transform: rotate(360deg);
    transition: all 2s;
    pointer-events: none;
    user-select: none;
  }
  .right_bottom {
    position: fixed;
    bottom: 120px;
    right: 50px;
    z-index: 200;

    img {
      display: block;
      width: 59px;
      height: 59px;
      margin-top: 35px;
    }
  }
  .right_bottom1 {
    position: fixed;
    bottom: 25px;
    right: 50px;
    z-index: 200;

    img {
      display: block;
      width: 59px;
      height: 59px;
      margin-top: 35px;
    }
  }
}
</style>
